.loader 
{
	border-top-color: #3498db;
	-webkit-animation: spinner 1.5s linear infinite;
	animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner 
{
	0% 
  {
		-webkit-transform: rotate(0deg);
	}
	100% 
  {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spinner 
{
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.slider 
{
  background              : #fefefe;
  box-shadow              : 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height                  : 100%;
  margin                  : auto;
  overflow                : hidden;
  position                : relative;
  width                   : 100%;
}

@media screen and (max-width : 700px)
{
  .slider
  {
    width           : 700px;
  }
}


.slider::before, .slider::after 
{
  background          : linear-gradient(to right, #fefefe 0%, rgba(255, 255, 255, 0) 100%);
  content             : "";
  height              : 100px;
  position            : absolute;
  width               : 20px;
  z-index             : 2;
}

.slider::after 
{
  right               : 0;
  top                 : 0;
  -webkit-transform   : rotateZ(180deg);
  transform           : rotateZ(180deg);
}

.slider::before 
{
  left                : 0;
  top                 : 0;
}

.slider .slide-track
{
  -webkit-animation   : scroll 35s linear infinite;
  animation           : scroll 35s linear infinite;
  display             : flex;
  width               : calc(250px * 60);
}

.slider .slide 
{
  width               : 140px;

  display             : flex;
  justify-content     : center;
  align-items         : center;
}

.bouncingMarker
{  
  animation: bounce 0.5s infinite alternate ease-in-out;
}

@keyframes bounce
{
  0% { transform: translateY(0), scale(1, 1) }
  50% { transform: translateY(-10px), scale(0.5, 1) }
  100% { transform: translateY(-20px) }
}

.markerShadow
{  
  filter: drop-shadow(-1px 2px 2px rgba(0, 0, 0, 0.534));
}